.status {
    position: absolute;
    bottom: 10px;
}

.status-box {
    float: left;
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    border: 1px solid black;
    clear: both;
}

.red {
    background-color: red;
}

.green {
    background-color: green;
}

.blue {
    background-color: blue;
}