/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 26, 2022 */



@font-face {
    font-family: 'brda_comextra_bold';
    src: url('brdacom-extrabold-webfont.woff2') format('woff2'),
         url('brdacom-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'brda_comextra_bold_italic';
    src: url('brdacom-extrabolditalic-webfont.woff2') format('woff2'),
         url('brdacom-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'loewheavy';
    src: url('loew-heavy-webfont.woff2') format('woff2'),
         url('loew-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'loewheavy_italic';
    src: url('loew-heavyitalic-webfont.woff2') format('woff2'),
         url('loew-heavyitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}