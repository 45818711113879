:root {
  --bs-primary: #FFF000;
  --bs-secondary: #6c757d;
  --bs-light: #feeecd;
  --bs-dark: #320853;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-body-bg: #69228e;
  --bs-border-width: 4px;
  --bs-border-style: solid;
  --bs-border-color: #feeecd;
  --bs-border-color-translucent: rgba(254, 238, 205, 0.3);
  --bs-border-radius: 0rem;
  --bs-border-radius-sm: 0rem;
  --bs-border-radius-lg: 0rem;
  --bs-border-radius-xl: 0rem;
  --bs-border-radius-2xl: 0rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #FF9600;
  --bs-link-hover-color: #d27c02;
}

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 1.15rem;
  --bs-btn-font-size: 1.25rem;
  font-family: 'loewheavy', sans-serif;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #feeecd;
  --bs-btn-hover-color: #FFF000;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-box-shadow: 0 0 0 1em rgba(var(--bs-btn-focus-shadow-rgb), .5);
   --bs-btn-border-width: 4px;
  --bs-btn-border-radius: 0rem;
}

.btn-primary{
  --bs-btn-color: #320853;
  --bs-btn-bg: #FFF000;
  --bs-btn-border-color: #FFF000;
  --bs-btn-hover-color: #320853;
  --bs-btn-hover-bg: #ffba00;
  --bs-btn-hover-border-color: #FFF000;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #8144a1;
  --bs-btn-active-bg: #ff9900;
  --bs-btn-active-border-color: #ff9900;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;  
}

.form-control:focus, .form-select:focus {
  color: inherit;
  background-color: inherit;
  border-color: #FFF000;
  outline: 0;
  box-shadow: 0 0 20px .25rem rgba(129, 68, 161, 0.397);
}

.app {
  font-family: 'loewheavy', sans-serif;
  color: #feeecd;
  border: 0px solid #feeecd;
  height: auto;
}

h1, h2, h3, h4, h5 {
  font-family: 'brda_comextra_bold_italic', sans-serif;
  text-transform: uppercase;
  color: #feeecd;
  text-shadow: .05em .05em 0em #1f0732;
}

.lead {
  color: #feeecd;
}

.lead b {
  color: #FFF000;
}

label {
  color: #feeecd;
  font-size: 1.15rem;
}

button, input, optgroup, select, textarea {
  font-family: 'loewheavy', sans-serif; 
}

.StartForm {
  background-color: #1f0732;
}

.list-group {
  --bs-list-group-border-radius: 0px;
  --bs-list-group-border-width: 0px;
  --bs-list-group-bg: #feeecd;
  --bs-list-group-border-color: transparent;
  --bs-list-group-color: #320853;
  --bs-list-group-item-padding-x: 1.15rem;
  --bs-list-group-item-padding-y: .75rem;
 border: 8px solid #feeecd;
}

.list-group-item:nth-child(even){
  --bs-list-group-bg: #f2e1c3;
}

.form-create, .form-join, .form-spec{
  min-height: 300px;
}

/* .btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 1.15rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #7deddb;
  --bs-btn-bg: #360e50;
  --bs-btn-border-width: 4px;
  --bs-btn-border-color: #7deddb;
  --bs-btn-border-radius: 0rem;
  --bs-btn-hover-color: #7deddb;
  --bs-btn-hover-border-color: #58bcac;
  letter-spacing: -.01rem;
}

.btn-primary {
  --bs-btn-color: #320853;
  --bs-btn-bg: #FFF000;
  --bs-btn-border-color: #FFF000;
  --bs-btn-hover-color: #320853;
  --bs-btn-hover-bg: #ffba00;
  --bs-btn-hover-border-color: #FFF000;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #8144a1;
  --bs-btn-active-bg: #ff9900;
  --bs-btn-active-border-color: #ff9900;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
} */

.nav {
  --bs-nav-link-padding-x: 2rem;
  --bs-nav-link-padding-y: .75rem;
  --bs-nav-link-font-weight: inherit;
  --bs-nav-link-color: #69228e;
  --bs-nav-link-hover-color: #feeecd;
  --bs-nav-link-hover-bg: #360e50;
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background: #1f0732;
  font-size: 1.15rem;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0px;
  --bs-nav-tabs-border-color: #feeecd;
  --bs-nav-tabs-border-radius: 0;
  --bs-nav-tabs-link-hover-border-color: #360e50;
  --bs-nav-tabs-link-active-color: #FFF000;
  --bs-nav-tabs-link-active-bg: #360e50;
  --bs-nav-tabs-link-active-border-color: #FFF000;
  border-bottom: transparent;
}

.card {
  --bs-card-border-width: 4px;
  --bs-card-border-color: #8144a1;
  --bs-card-border-radius: 0;
  --bs-card-bg: #feeecd;
}

label.card-text {
  color: #320853 !important;
}

/* .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #FFF000;
  color: #320853;
  border: none;
  border-radius: 0;
  padding: 
} */

.form-control, .form-select, input {
  background-color: #360e50;
  color: #FFF000;
  border: 4px solid #69228e; 
}

.form-control-lg, .form-select {
  padding: 1.15rem 1.5rem;
  border-radius: 0rem;
}

.form-select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0ibTIgNSA2IDYgNi02Ii8+PC9zdmc+);
  background-size: 30px 14px;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8144a1 !important;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #8144a1 !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #8144a1 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.DraftStart {
  border: 4px solid #feeecd;
}

.selection-error {
  color: red;
}

.status {
  display: none;
}

.ko-dtabs {
  background-color: #360e50;
}


.ko-draftinfo {
  color: #feeecd;
  margin-bottom: 20px;
  text-align: right;
}

.ko-violet {
  background-color: #360e50;
  padding: 24px 12px;
}

.ko-violet label {
  font-family: 'loewheavy', sans-serif; 
  color: #feeecd;
  display: block;
  border-bottom: 4px solid #feeecd;
}

.ko-violet label.secondary {
  border-bottom: 0px solid #feeecd; 
}

.ko-draft {
  background-color: #feeecd;
}

.ko-draft label {
  font-family: 'loewheavy', sans-serif; 
  color: #69228e;
  display: block;
  border-bottom: 4px solid #69228e;
}

.ko-draftinfo strong, .ko-violet label b {
  color: #FFF000;
  font-weight: inherit;
}

.ko-slctbtn .btn-primary {
  font-size: 1.25rem;
  background-color: #1f0732;
  color: #feeecd;
  border: 4px solid #1f0732;
  border-radius: 0;
  text-align: left;
  padding: 1.15rem 1.5rem;
}

.ko-slctbtn .btn-primary:hover {
  color: inherit;
  border: 4px solid #FFF000;
  background-color: #360e50; 
}

.ko-selects label{
  text-transform: capitalize;
  color: #fff;
  width: 100%;
}

.ko-item {
  border: 4px solid #feeecd;
  background-color: transparent;
  transition: 200ms all;
}

.ko-item:hover {
  border: 4px solid #FFF000;
  background-color: #1f0732;
  transition: 200ms all;
}

.ko-item:hover label {
  color: #FFF000;
}

.ko-item label {
  font-size: 1rem;
}

.ko-item:hover img {
  opacity: 1;
}

.ko-draftname {
  font-family: 'brda_comextra_bold_italic', sans-serif;
  text-transform: uppercase;
  color: #FFF000;
  background-color: #1f0732;
}

.alert {
  --bs-alert-border: 4px solid #FF9600;
  --bs-alert-border-radius: 0rem;
  color: #320853;
  background-color: #ffcc84;
}
