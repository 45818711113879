.selectable-item {
    display: inline-block;
    padding: 10px;
}

.selectable-item-image {
    height: 100px;
}

.selectable-item-overlay {
    height: 100px;
    position: absolute;
}

.selectable-item-hidden {
    display: none;
}

.parent-style {
    width: 200px;
}